import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import ReviewPageCard from "../components/review-page-card";
import HowCanWeHelpEr from "../components/how-can-we-help-er";
import PhotoLeft from "../components/photo-left";
import BeeBanner from "../components/bee-banner";
import WhatWeDoHero from "../components/what-we-do-hero";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { GoPrimitiveDot } from "react-icons/go";
import SVGFBEr1 from "../components/SVG/flying-bee-er-1";
import SVGFBEr2 from "../components/SVG/flying-bee-er-2";
import SVGERBgPoly from "../components/SVG/er-bg-poly";
import { Container } from "react-bootstrap";

const Testimonials = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Review-Page-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpReview(
				sort: { fields: dateGmt, order: DESC }
				filter: { Reviewinfo: { onReviewPage: { eq: true } } }
			) {
				nodes {
					title
					Reviewinfo {
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: TRACED_SVG
									)
								}
							}
						}
						companyName
						name
						rating
						review
						onHeroImage
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Testimonials",
				item: {
					url: `${siteUrl}/testimonials`,
					id: `${siteUrl}/testimonials`,
				},
			},
		],
	};

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="Testimonials | Hive HR Solutions"
					description="Read our Testimonials"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/what-we-do/employee-relations`,
						title: "Testimonials | Hive HR Solutions",
						description: "Read our Testimonials",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<section className="position-relative">
					<SVGFBEr1
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top-10 d-none d-lg-block "
					/>
					<WhatWeDoHero
						hideSHeading
						title="Testimonials"
						img={
							<Row>
								<Col>
									<GatsbyImage
										className="mw-100 tImage"
										alt={data.heroImg?.altText}
										image={heroImg}
									/>
								</Col>
							</Row>
						}
					/>
				</section>
				<section className="position-relative pb-lg-8 pt-lg-4 pb-4">
					<SVGERBgPoly
						style={{ zIndex: -2 }}
						className="position-absolute end-0 bottom--30 d-none d-lg-block "
					/>{" "}
					<Container>
						<Row className="justify-content-center ">
							{data.allWpReview.nodes.map((review) => (
								<Col md={6} lg={4}>
									<ReviewPageCard review={review} />
								</Col>
							))}
						</Row>
					</Container>
				</section>

				<section>
					<BeeBanner
						headline="Call now for more information"
						btnTxt="Call us"
						btnLink="tel:+441403627630"
					/>
				</section>
			</Layout>
		);
	}
};

export default Testimonials;
