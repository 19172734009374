import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SVGPolygon from "./SVG/polygon";

const WhatWeDoHero = ({
	title,
	description,
	img,
	pos,
	hideSHeading,
	showDesc,
}) => {
	const position = pos || "-1.2rem";
	return (
		<div>
			<Container className="pt-lg-6 pt-4">
				<Row className=" pt-3  ">
					<Col lg={8} xl={7} className="pl-0 pb-lg-3 ">
						<h4 className={` ${hideSHeading ? "d-none" : ""}`}>What we do</h4>
						<div className="position-relative d-inline-block">
							<SVGPolygon
								className="position-absolute d-none d-xl-block"
								style={{ zIndex: -1, right: position, top: "-1.5rem" }}
							/>
							<h1 className="pt-1 display-5 d-inline-block mb-3">{title}</h1>
						</div>

						<hr className="hr-primary" />
						<div className="d-lg-none mt-5">{img}</div>
						{showDesc === true && (
							<p className="mb-lg-0 pt-4 pt-xl-0  fs-4 fw-normal pb-3">
								{description}
							</p>
						)}
					</Col>
				</Row>
				<Row className="d-lg-block d-none">
					<Col>{img}</Col>
				</Row>
			</Container>
		</div>
	);
};

export default WhatWeDoHero;
