import * as React from "react";

const SVGDottedPatternBlack = (props) => (
	<svg
		width={116}
		height={129}
		viewBox="0 0 116 129"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<circle cx={7.93103} cy={7.93103} r={7.93103} fill="#121212" />
		<circle cx={7.93103} cy={92.7587} r={7.93103} fill="#121212" />
		<circle cx={82.4139} cy={92.7587} r={7.93103} fill="#121212" />
		<circle cx={82.4139} cy={7.93103} r={7.93103} fill="#121212" />
		<circle cx={32.7587} cy={7.93103} r={7.93103} fill="#121212" />
		<circle cx={32.7587} cy={92.7587} r={7.93103} fill="#121212" />
		<circle cx={107.242} cy={92.7587} r={7.93103} fill="#121212" />
		<circle cx={107.242} cy={7.93103} r={7.93103} fill="#121212" />
		<circle cx={32.7587} cy={7.93103} r={7.93103} fill="#121212" />
		<circle cx={32.7587} cy={92.7587} r={7.93103} fill="#121212" />
		<circle cx={107.242} cy={92.7587} r={7.93103} fill="#121212" />
		<circle cx={107.242} cy={7.93103} r={7.93103} fill="#121212" />
		<circle cx={57.5863} cy={7.93103} r={7.93103} fill="#121212" />
		<circle cx={57.5863} cy={92.7587} r={7.93103} fill="#121212" />
		<circle cx={7.93103} cy={36.2069} r={7.93103} fill="#121212" />
		<circle cx={7.93103} cy={121.035} r={7.93103} fill="#121212" />
		<circle cx={82.4139} cy={121.035} r={7.93103} fill="#121212" />
		<circle cx={82.4139} cy={36.2069} r={7.93103} fill="#121212" />
		<circle cx={32.7587} cy={36.2069} r={7.93103} fill="#121212" />
		<circle cx={32.7587} cy={121.035} r={7.93103} fill="#121212" />
		<circle cx={107.242} cy={121.035} r={7.93103} fill="#121212" />
		<circle cx={107.242} cy={36.2069} r={7.93103} fill="#121212" />
		<circle cx={32.7587} cy={36.2069} r={7.93103} fill="#121212" />
		<circle cx={32.7587} cy={121.035} r={7.93103} fill="#121212" />
		<circle cx={107.242} cy={121.035} r={7.93103} fill="#121212" />
		<circle cx={107.242} cy={36.2069} r={7.93103} fill="#121212" />
		<circle cx={57.5863} cy={36.2069} r={7.93103} fill="#121212" />
		<circle cx={57.5863} cy={121.035} r={7.93103} fill="#121212" />
		<circle cx={7.93103} cy={64.4828} r={7.93103} fill="#121212" />
		<circle cx={82.4139} cy={64.4828} r={7.93103} fill="#121212" />
		<circle cx={32.7587} cy={64.4828} r={7.93103} fill="#121212" />
		<circle cx={107.242} cy={64.4828} r={7.93103} fill="#121212" />
		<circle cx={32.7587} cy={64.4828} r={7.93103} fill="#121212" />
		<circle cx={107.242} cy={64.4828} r={7.93103} fill="#121212" />
		<circle cx={57.5863} cy={64.4828} r={7.93103} fill="#121212" />
	</svg>
);

export default SVGDottedPatternBlack;
