import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import SVGDottedPattern from "./SVG/dotted-pattern";
import SVGDottedPatternBlack from "./SVG/dotted-pattern-black";
import SVGPolygon from "./SVG/polygon";

const HowCanWeHelpEr = ({
	heading,
	text,
	img,
	imgAlt,
	width,
	height,
	list,
}) => {
	const newWidth = width || "30rem";
	const newHeight = height || "30rem";
	return (
		<section className="my-lg-5 pb-lg-10 ">
			<Container>
				<Row className="align-items-center">
					<Col className="  pt-lg-5  pt-md-3 pt-xl-0" xs={12} xl={6}>
						<div className="position-relative">
							<SVGPolygon
								className="position-absolute d-none d-lg-block"
								style={{ zIndex: -1, left: "-1rem", top: "-2.5rem" }}
							/>
							<h2 className=" text-start  mb-3">{heading}</h2>
						</div>
						<hr className="hr-primary" />

						<p className="text-start">{text}</p>
						<div xs={12} className="d-xl-none mt-4">
							<GatsbyImage className="mb-4" alt={imgAlt} image={img} />
						</div>
						<div
							style={{
								background:
									"linear-gradient(102.21deg, #fbba00 45.22%, rgba(251, 180, 23, 0.68) 98.07%)",
							}}
							className="mt-xl-5 mt-3 p-4 pe-6  d-inline-block position-relative"
						>
							<div>
								<h4>We can help you by:</h4>
							</div>
							<div
								style={{
									width: "40rem",
									boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
								}}
								className="position-absolute start-10 top-80 bg-white pt-4 p-4 d-lg-block d-none"
							>
								<div className="w-100 h-100 position relative">
									{list}{" "}
									<SVGDottedPatternBlack
										style={{ right: "-3rem", bottom: "-3.2rem", zIndex: "-2" }}
										className="position-absolute d-none d-lg-block  "
									/>
								</div>
							</div>
						</div>
						<div
							style={{
								boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
							}}
							className="w-100 h-100 position relative p-3 mt-4 d-lg-none"
						>
							{list}{" "}
						</div>
					</Col>
					<Col xs={12} lg={6} className="mb-4 pt-3 d-none d-xl-block  mb-md-0">
						<div
							className="position-relative mx-auto mt-7 "
							style={{ height: newHeight, width: newWidth }}
						>
							<GatsbyImage
								className="position-absolute"
								style={{ height: newHeight, width: newWidth }}
								alt={imgAlt}
								image={img}
							/>{" "}
							<SVGDottedPattern
								style={{ right: "-3rem", bottom: "-3.2rem", zIndex: "-2" }}
								className="position-absolute d-none d-lg-block  "
							/>
						</div>
					</Col>
				</Row>
				<div className="d-none d-lg-block" style={{ height: "40rem" }}></div>
			</Container>
		</section>
	);
};

export default HowCanWeHelpEr;
