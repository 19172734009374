import * as React from "react";

const SVGERBgPoly = (props) => (
	<svg
		width={382}
		height={1091}
		viewBox="0 0 382 1091"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M679.744 270.684C683.317 270.684 686.618 272.59 688.405 275.684L866.315 583.834C868.101 586.928 868.101 590.74 866.315 593.834L688.405 901.983C686.618 905.077 683.317 906.983 679.744 906.984L323.924 906.984C320.351 906.984 317.05 905.078 315.263 901.983L137.353 593.834C135.567 590.74 135.567 586.928 137.353 583.834L315.263 275.684C317.05 272.59 320.351 270.684 323.924 270.684L679.744 270.684Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
		<path
			d="M690.744 183.684C694.317 183.684 697.618 185.59 699.405 188.684L877.315 496.834C879.101 499.928 879.101 503.74 877.315 506.834L699.405 814.983C697.618 818.077 694.317 819.983 690.744 819.984L334.924 819.984C331.351 819.984 328.05 818.078 326.263 814.983L148.353 506.834C146.567 503.74 146.567 499.928 148.353 496.834L326.263 188.684C328.05 185.59 331.351 183.684 334.924 183.684L690.744 183.684Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGERBgPoly;
