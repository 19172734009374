import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Link from "./link";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

const ReviewPageCard = ({ review }) => {
	var trimmedString = review.Reviewinfo.review.substr(0, 180);
	trimmedString =
		trimmedString.substr(
			0,
			Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
		) + "...";

	const [reviewBody, setReviewBody] = useState(review.Reviewinfo.review);
	const [linkText, setlinkText] = useState("Read More");
	const [expanded, setExpanded] = useState(false);
	const handleClick = () => {
		if (!expanded) {
			setReviewBody(review.Reviewinfo.review);
			setlinkText("Read Less");
			setExpanded(true);
		} else {
			setReviewBody(trimmedString);
			setlinkText("Read More");
			setExpanded(false);
		}
	};
	if (!expanded) setReviewBody(trimmedString);
	return (
		<Card
			style={{ boxShadow: " 0px 4px 6px #0000000d", opacity: 1 }}
			className="border-0 my-4 mx-0"
		>
			<Card.Header className="bg-white border-0">
				<Row className="align-items-center">
					<Col xs={7} className="pe-0">
						<Card.Title className="mb-2">{review.Reviewinfo.name}</Card.Title>
						<p className="mb-0 pb-0">{review.Reviewinfo.companyName}</p>
						<Row className="align-items-center mt-0 ">
							<Col className="d-flex align-items-center">
								<p className="d-inline-block pb-0 mb-0 fw-regular">
									{review.Reviewinfo.rating}
								</p>
								<StaticImage
									quality="100"
									className="d-inline-block ms-1"
									src="../images/icons/star.svg"
									alt="star"
									placeholder="blurred"
									width={15}
									formats={["auto", "webp"]}
								/>
							</Col>
						</Row>
					</Col>
					<Col
						xs={5}
						className={` d-flex justify-content-center align-items-center `}
					>
						{" "}
						<GatsbyImage
							className=" mw-60 "
							image={
								review.Reviewinfo.image?.localFile.childImageSharp
									?.gatsbyImageData
							}
							alt={review.Reviewinfo.image.altText}
						></GatsbyImage>
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						<Card.Text className="text-start">
							<p>
								<span className="text-primary">"</span>
								{reviewBody}
								<span className="text-primary">"</span>
							</p>
						</Card.Text>
					</Col>
				</Row>
				<Row>
					<Col className="text-start">
						<Link
							className="link-orange text-decoration-none fw-bold "
							onClick={handleClick}
						>
							{linkText}
						</Link>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default ReviewPageCard;
